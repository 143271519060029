import React, {useEffect, useState} from 'react';
import Slider from '@material-ui/core/Slider';


const RangeNumberGeneEditor = (props) => {

  const [value, setValue] = useState(props.gene["value"])
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (!editing && value !== props.gene["value"]) {
      setValue(props.gene["value"])
    }
  });

  return <Slider
      defaultValue={props.gene["value"]}
      value={value}
      step={1}
      marks
      valueLabelDisplay="on"
      min={props.gene["min_value"]}
      max={props.gene["max_value"]}
      
      onChange={(evt, value) => {
        setEditing(true);
        setValue(value);
      }}
      onChangeCommitted={(evt, value) => {
        setEditing(false);
        props.gene["value"] = value
        props.onChangeCommitted(props.gene)
      }}
    />
}

export default RangeNumberGeneEditor;