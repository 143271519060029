import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SpinnerView from '../components/SpinnerView';


const withUser = (Component) => {
  class ComponentWithUser extends React.Component {
      render() {
          if (this.props.user === undefined || this.props.customer === undefined) {
              return <SpinnerView loadClass="full-spinner"/>
          }

          return <Component user={this.props.user} {...this.props} />
      }
  }

  return connect(
    (state) => ({
        user: _.get(state, 'auth.user'),
        customer: _.get(state, 'subscribe.customer')
    }),
    (dispatch) => ({})
    )(ComponentWithUser)
}



export default withUser;
