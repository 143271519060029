import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { useStripe } from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Divider, List, ListItem, Radio, Tab, Tabs, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { subscriptionTiers } from './tiers';
import { clearApiError } from '../../actions/ErrorActions';

import { clearCreditsCheckoutSessionUrl, createCreditsCheckoutSession, getOnDemandPlans } from '../../actions/SubscribeActions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
          <Box pb="16px">{children}</Box>
      )}
    </div>
  );
}


const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: 360,
    display: 'flex',
    flexDirection: 'column',
  },
  drawerTitle: {
    fontWeight: 700
  },
  tabPanelTitle: {
    fontSize: '0.83em',
    fontWeight: 'bold'
  },
  planList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  planListItem: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgba(12, 18, 28, 0.12)',
  },
  panelListItemContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  planTitle: {
    fontSize: '0.875rem',
    fontWeight: 700
  },
  planPrice: {
    fontSize: '0.75rem',
    fontWeight: 400
  },
  downloadButton: {
    backgroundColor: '#f54336',
    width: '100%',
    borderRadius: '25px',
    textTransform: 'none'
  },
  cancelButton: {
    textTransform: 'none',
    color: '#0070f0',
    fontWeight: 400
  }
}));

function toFixNoRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.floor(number * factor) / factor
}

const SubscriptionPlansListItems = (props) => {
  const classes = useStyles();

  const [selectedSubscriptionTier, setSelectedSubscriptionTier] = useState();
  
  const currentSubscribedProduct = _.get(props, 'customer.currentSubscribedProduct', null);

  return (
    subscriptionTiers.map((tier, index) => (
      <ListItem key={tier["title"]} disabled={tier["name"] === currentSubscribedProduct}>
        <Box display="flex" flexDirection="row" width="100%" paddingTop={index > 0 ? "8px" : "0px"} paddingBottom="8 px">
          <Radio 
            checked={selectedSubscriptionTier === tier}
            onChange={() => {
              ReactGA.event({
                category: 'Paywall',
                action: 'SubscriptionPlanSelected',
                label: `Name=${tier["name"]}`
              });
              setSelectedSubscriptionTier(tier);
              props.tierSelected(tier)
            }}/>

          <Box display="flex" justifyContent="space-between" width="100%">
          
            <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="center">
              <Typography className={classes.planTitle} variant="body2">
                {tier["name"]} {tier["name"] === currentSubscribedProduct ? "(Subscribed)" : undefined}
              </Typography>
              <Typography className={classes.planPrice} variant="caption">
                {tier["description"]}
              </Typography>
            </Box>
            
            <Box display="flex" alignItems="flex-end" flexDirection="column" justifyContent="center">
              <Typography variant='body2'>
                <span 
                  className={classes.planTitle}>
                    US${tier["price"]}/mo*
                </span>
              </Typography>
              </Box>
            </Box>

        </Box>
  
       
      </ListItem>
    ))
  )
}

const Paywall = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();

  const [selectedPlanType, setSelectedPlanType] = React.useState(0);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = React.useState(0);
  const [closed, setClosed] = React.useState(true);
  const creatingCheckoutSession = useSelector((state) => state.subscribe.creatingCheckoutSession);
  const onDemandPlans = useSelector((state) => state.subscribe.onDemandPlans);
  const stripeCheckoutSessionId = useSelector((state) => state.subscribe.sessionId);
  const customer = useSelector((state) => state.subscribe.customer);
  const globalError = useSelector((state) => _.get(state, 'error.error', undefined));
  const [selectedOnDemandPlan, setSelectedOnDemandPlan] = React.useState(onDemandPlans ? onDemandPlans[0]: undefined);

  useEffect(() => {
    
    dispatch(getOnDemandPlans());
  }, [])

  useEffect(() => {
    if (!closed) {
      ReactGA.pageview("paywall");
    }
  })

  useEffect(() => {
    if (globalError && globalError["type"] === "SubscriptionLimitExceeded") {
      dispatch(clearApiError());
      setClosed(false); 
    }
  });

  useEffect(() => {
    if (!selectedOnDemandPlan && onDemandPlans) {
      setSelectedOnDemandPlan(onDemandPlans[0]);
    }    
  })

  useEffect(() => {
    if (stripeCheckoutSessionId) {
      dispatch(clearCreditsCheckoutSessionUrl());
      stripe.redirectToCheckout({
        sessionId: stripeCheckoutSessionId
      });
    }  
  })
  
  const planListItems = onDemandPlans ? onDemandPlans.map((plan, index) => (
    
    <ListItem key={plan["id"]} divider={index < onDemandPlans.length - 1}>
      <Box display="flex" flexDirection="row" width="100%" paddingTop={index > 0 ? "8px" : "0px"} paddingBottom="8 px">
        <Radio 
          checked={selectedOnDemandPlan === plan}
          onChange={() => {
            ReactGA.event({
              category: 'Paywall',
              action: 'OnDemanPlanSelected',
              label: `Quantity=${plan["quantity"]}`
            });
            setSelectedOnDemandPlan(plan)
          }}/>

        <Box display="flex" justifyContent="space-between" width="100%">

          <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="center">
            <Typography className={classes.planTitle} variant="body2">{plan["quantity"]} image credits</Typography>
            <Typography className={classes.planPrice} variant="caption"><strong>{`US$${plan["totalAmount"]/100}`}</strong></Typography>
          </Box>
        
          <Box display="flex" alignItems="flex-end" flexDirection="column" justifyContent="center">
            <Typography variant='body2'>
              <span className={classes.planTitle}>US${toFixNoRound((plan["totalAmount"]/100)/plan["quantity"], 2)}</span>
            </Typography>
            <Typography variant="caption">
              per image
            </Typography>
          </Box>

        </Box>
        
      </Box>
    </ListItem>
    
  )) : ""
          console.log(selectedOnDemandPlan)
  return (
    <Drawer
      id="styles-drawer"
      className={classes.drawer}
      anchor='right'
      open={!closed}
      classes={{
        paper: classes.drawerPaper,
      }}>
        <Box display="flex" justifyContent="flex-end" p="12px">
          <Close onClick={()=> setClosed(true)}/>
        </Box>
        <Box display="flex" flexDirection="column" pl="16px" pr="16px" pb="16px" width="100%" overflow="hidden">
          <Box>
            <Box pb="36px">
              <Typography className={classes.drawerTitle} variant="body1">Ready to purchase?</Typography>
              <Typography variant="caption">Choose your plan below and beign downloading images.</Typography>
            </Box>
          </Box>
          <Box pb="24px">
            <Tabs
              value={selectedPlanType}
              onChange={(evt, newValue) => setSelectedPlanType(newValue)}>
              <Tab label="On-demand credits"></Tab>
              <Tab label="Subscriptions"></Tab>
            </Tabs>
          </Box>
          
          <TabPanel value={selectedPlanType} index={0}>
            <Box paddingBottom="16px">
              <Box marginBottom="19px">
                <Typography className={classes.tabPanelTitle} variant="h5">Download images with no monthly commitments</Typography>
              </Box>
              <Box border='1px solid rgba(12, 18, 28, 0.12)' borderRadius={4}>
                <List classes={{root: "{border: '1px solid rgba(12, 18, 28, 0.12)'}"}}>
                  {planListItems}
                </List>
              </Box>
            </Box>
            <Box pl="16px" pr="16px" pb="16px">
              <Typography variant="caption">Prices shown in US dollars. Taxes may apply.</Typography>
            </Box>

            <Divider/>

            <Box pt="16px">
              <Button 
                className={classes.downloadButton}
                variant="contained" 
                color="secondary" 
                disableElevation
                endIcon={creatingCheckoutSession ? <CircularProgress size={24} color="inherit"/> : undefined}
                disabled={_.isUndefined(selectedOnDemandPlan)}
                onClick={() => {
                  ReactGA.event({
                    category: 'Paywall',
                    action: `BuyCreditsButton`,
                    label: `Quantity=${selectedOnDemandPlan['quantity']}`
                  });
                  dispatch(createCreditsCheckoutSession(selectedOnDemandPlan))}
                }>
                  Buy and download
              </Button>
            </Box>
            
          </TabPanel>
          
          <TabPanel value={selectedPlanType} index={1}>
           <Box paddingBottom="16px">
              <Box marginBottom="19px">
                <Typography className={classes.tabPanelTitle} variant="h5">Download images with no monthly commitments</Typography>
              </Box>
              <Box border='1px solid rgba(12, 18, 28, 0.12)' borderRadius={4}>
                <List classes={{root: "{border: '1px solid rgba(12, 18, 28, 0.12)'}"}}>
                  <SubscriptionPlansListItems tierSelected={setSelectedSubscriptionPlan} customer={customer}/>
                </List>
              </Box>
            </Box>

            <Box pl="16px" pr="16px" pb="16px">
              <Typography variant="caption">* Prices shown in US dollars. Taxes may apply.</Typography>
              <br/>
              <Typography variant="caption">* Billed monthly</Typography>
            </Box>

            <Divider/>

            <Box pt="16px">
              <Button 
                className={classes.downloadButton}
                variant="contained" 
                color="secondary" 
                disableElevation
                disabled={selectedSubscriptionPlan === 0}
                onClick={() => {

                  ReactGA.event({
                    category: 'Paywall',
                    action: `BuySubscription`,
                    label: selectedSubscriptionPlan["name"]
                  })

                  const route = customer && customer.currentSubscribedProduct ? 
                                "customer-portal" : selectedSubscriptionPlan.route;

                  props.history.push(route);
                }
                }>
                  {customer && 
                    customer.currentSubscribedProduct && 
                    customer.currentSubscribedProduct === "Designer" ? "Upgrade to Unlimited plan" : "Upgrade"}
                </Button>
            </Box>

          </TabPanel>

          
          <Button 
            className={classes.cancelButton}
            onClick={()=> {
              ReactGA.event({
                category: 'Paywall',
                action: 'Cancel'
              })
              setClosed(true);
            }}>
              Cancel
          </Button>
        </Box>
        


    </Drawer>
  )
}

const StripePaywall = (props) => (
  <Elements stripe={stripePromise}>
    <Paywall {...props} />
  </Elements>
);




export default withRouter(StripePaywall);