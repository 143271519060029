import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from "react-redux";

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import withUser from '../../hoc/withUser'
import ReactGA from 'react-ga';
import { ButtonGroup, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { tiers } from './tiers';
import { clearCreditsCheckoutSessionUrl, createCreditsCheckoutSession, getOnDemandPlans } from '../../actions/SubscribeActions';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://kandinsky.io/">
        Kandinsky.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  onDemandCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },

  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));


const footers = [
  {
    title: 'Company',
    description: [{label: 'Contact us'}],
  },
  {
    title: 'About',
    description: [{label: 'Who built this?'}],
  },
  {
    title: 'Legal',
    description: [{label: 'Privacy policy', route: '/privacy'}, {label: 'Terms of use', route: '/terms'}],
  },
];

let Pricing = (props) => {
    const classes = useStyles();
    
    const [selectedOnDemandPlan, setSelectedOnDemandPlan] = useState(undefined);

    const getOnDemandPlansInProgress = useSelector((state) => state.subscribe.getOnDemandPlansInProgress);
    const onDemandPlans = useSelector((state) => state.subscribe.onDemandPlans);
    const stripeCheckoutSessionId = useSelector((state) => state.subscribe.sessionId);
    const stripe = useStripe();
    const dispatch = useDispatch();

    if (stripeCheckoutSessionId) {
      dispatch(clearCreditsCheckoutSessionUrl());
      stripe.redirectToCheckout({
        sessionId: stripeCheckoutSessionId
      });
      return;
    }

    useEffect(()=>{
      console.log('pageview', window.location.pathname);
      ReactGA.pageview(window.location.pathname);

      if (_.isUndefined(getOnDemandPlansInProgress) && _.isUndefined(onDemandPlans)) {
        dispatch(getOnDemandPlans())
      }

      if (!_.isUndefined(onDemandPlans) && _.isUndefined(selectedOnDemandPlan)) {
        setSelectedOnDemandPlan(onDemandPlans[0]);
      }
    });

    return (
      <div>
        <CssBaseline />
        {/* Hero unit */}
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Pricing
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" component="p">
            All plans include access to all features. Only downloads are counted towards your subscription.
          </Typography>
        </Container>
        {/* End hero unit */}
        <Container fixed component="main">
          <Grid container spacing={2} alignItems="flex-end">
            {tiers.map((tier) => {
              let buttonPropsKey;
              let buttonText = '';
              let subheader = '';
              let route = '';
              

              if (props.user) {
                buttonPropsKey = 'signedIn';

                if (tier.title === 'Individual') {
                  buttonText = "Let's Create!"
                  route = '/'
                } else {
                  const customer = props.customer;

                  if (customer && customer.currentSubscribedProduct) {
                    let product = customer.currentSubscribedProduct;

                    if (tier.title === product) {
                      subheader = "Subscribed!";
                    }

                    if (product === "Designer") {
                      if (tier.title === "Designer") {
                        buttonText = "Manage Subscription";
                        route = "/customer-portal";
                      } else {
                        buttonText = "Upgrade";
                        route = "/customer-portal";
                      }
                    }

                    if (product === "Unlimited") {
                      buttonText = "Manage Subscription";
                      route = "/customer-portal"
                    }
                  } else {
                    buttonText = "Upgrade"
                    route = tier.button[buttonPropsKey].route;
                  }
                } 
              } else { // Not signed in
                buttonPropsKey = 'signedOut';
                route = tier.button[buttonPropsKey].route;
                buttonText = tier.title === "Individual" ? "Sign Up" : "Sign up and subscribe"
              }

              let actionButton = (
                <Button 
                  fullWidth 
                  variant={tier.button[buttonPropsKey].buttonVariant} 
                  color="primary"
                  href={route}
                  onClick={(e) => {
                      ReactGA.event({
                        category: "Pricing",
                        action: `${tier["title"]}Clicked`
                      })
                  }}
                >{buttonText}</Button>  
              )
              // Enterprise card is full width at sm breakpoint
              return <Grid item key={tier.title} xs={12} sm={tier.title === 'Individual' ? 12 : 6} md={3}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={subheader ? subheader : tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Designer' ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography component="h2" variant="h3" color="textPrimary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /mo
                      </Typography>
                    </div>

                    <Box marginBottom="16px">
                      <Typography align="center"><strong>Downloads per month</strong></Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" marginBottom="16px">
                      <Button disableElevation variant="outlined" color="primary">{tier["downloadsPerMonth"]}</Button>
                    </Box>
                    
                    <ul>
                      {tier.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    {actionButton}
                  </CardActions>
                </Card>
              </Grid>
            })}

            <Grid item xs={12} sm={12} title="On-Demand" md={3}>
              <Card>
                <CardHeader
                    title="On-Demand"
                    //subheader="Purchase credits"
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
                <CardContent>
                  <div className={classes.cardPricing}>
                      {selectedOnDemandPlan && (
                        <Box>
                          <Typography component="h2" variant="h3" color="textPrimary">
                            {`$${selectedOnDemandPlan["totalAmount"]/100}`}
                          </Typography>
                          {/* <Typography variant="h6" color="textSecondary">
                            /{selectedOnDemandPlan["quantity"]} images
                          </Typography> */}
                        </Box>
                      )}
                      
                  </div>
                  <div className={classes.onDemandCard}>  
                    <Box marginBottom="16px">
                      <Typography><strong>Image download credits</strong></Typography>
                    </Box>
                    <ButtonGroup  color="primary" >
                      {
                        onDemandPlans && onDemandPlans.map(p => 
                          <Button 
                            variant={selectedOnDemandPlan && selectedOnDemandPlan["id"] === p["id"] ? "contained": "outlined"} 
                            onClick={()=> {setSelectedOnDemandPlan(p)}}>
                              {p["quantity"]}
                          </Button>
                        )
                      }
                    </ButtonGroup>
                  </div>
                  <ul>
                      <Typography component="li" variant="subtitle1" align="center">
                        Access to all features
                      </Typography>
                      <Typography component="li" variant="subtitle1" align="center">
                        Max resolution: 3840x3840
                      </Typography>
                  </ul>
                </CardContent>
                <CardActions>
                  {
                    props.user ? (
                      <Button 
                        fullWidth 
                        variant='outlined'
                        color="secondary"
                        //href={route}
                        onClick={(e) => {
                            ReactGA.event({
                              category: "Pricing",
                              action: "OnDemandBuyClicked"
                            })

                          dispatch(createCreditsCheckoutSession(selectedOnDemandPlan))
                        }}
                      >
                        Buy Credits
                      </Button>
                    ): <Button 
                          fullWidth 
                          variant="outlined" 
                          color="primary"
                          href="/signin"
                          >
                            Sign up and buy
                        </Button>
                  }
                  
                </CardActions>
              </Card>
            </Grid>
          </Grid>
                    <Box marginTop="16px">
                      <Typography align="center">Prices are shown in US dollars. Taxes may apply.</Typography>
                    </Box>
            
        </Container>
        
        {/* Footer */}
  
        <Container maxWidth="md" component="footer" className={classes.footer}>
          <Grid container spacing={4} justify="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title}>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item) => (
                    <li key={item}>
                      <Link href={item.route ? item.route : '#'} variant="subtitle1" color="textSecondary">
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
        {/* End footer */}
      </div>
    );
}

Pricing = withUser(Pricing);

const StripePricing = (props) => (
  <Elements stripe={stripePromise}>
    <Pricing {...props} />
  </Elements>
);


export default StripePricing;