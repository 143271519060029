
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import {Auth} from 'aws-amplify';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Help from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import SpinnerView from '../../components/SpinnerView';
import ReactGA from 'react-ga';

// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//   },
// }))(Tooltip);

const SubscriptionStatus = {
  "past_due": "Past Due",
  "active": "Active",
  "unpaid": "Unpaid",
  "cancelled": "Canceled" 
}

const styles = theme => ({
  profileMenuContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  profileMenuHeader: {
    padding: 30,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  profileMenuAction: {
    margin: 'auto',
    padding: 10
  },
  round: {
    borderRadius: '20px'
  },
  tooltip: {
    marginLeft: 5,
    fontSize: 16
  }
});


class Profile extends React.Component {

  handleSignout() {
    Auth.signOut();
  }

  componentDidMount() {
    ReactGA.pageview("profile");
  }

  render() {
    let {classes, customer} = this.props;
    
    let currentUsage = undefined;

    if (!customer) {
      return <SpinnerView/>
    }
    
    if (customer && customer.currentSubscribedProduct) {
      currentUsage = customer.productUsage[`${customer.currentPeriodStart}-${customer.currentPeriodEnd}`];
    } else {
      const d = new Date();
      currentUsage = _.get(customer, `freeTierUsage[${d.getFullYear()}-${d.getMonth()+1}]`, 0);
    }

    return <div className={classes.profileMenuContent}>
      <div className={classes.profileMenuHeader}>
        <IconButton disabled ria-controls="simple-menu" aria-haspopup="true">
          <AccountCircle style={{fontSize: 60}}/>
        </IconButton>
        
        <Typography variant="subtitle1" gutterBottom>{_.get(this.props.user, 'attributes.email')}</Typography>
        {
          customer ? 
            <React.Fragment>
              <Typography variant="body1" gutterBottom>
                  Subscription Plan: {!customer.currentSubscribedProduct ? "Individual (FREE)" : customer.currentSubscribedProduct}
              </Typography>
              
              {
                customer.currentSubscribedProduct ? 
                  <React.Fragment>
                    <Typography variant="body1" gutterBottom>
                      Subscription Status: {_.get(SubscriptionStatus, customer.subscriptionStatus, "Unknown")}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      Current period end: {<Typography variant="overline">{new Date(customer.currentPeriodEnd * 1000).toDateString()}</Typography>}
                    </Typography>
                  </React.Fragment> : 
                  null
              } 
              
            </React.Fragment> : 
            null
        }
        
        <Typography variant="body1" gutterBottom>
          
          Images downloaded in subscription period: {currentUsage}
          
          <Tooltip 
            arrow
            title="Number of unique image resolutions you download count towards your usage limit.">
            <Help className={classes.tooltip} />
          </Tooltip>

        </Typography>

        <Typography variant="body1" gutterBottom>
          Image Credits : {customer.credits}
        </Typography>

        {customer && customer.currentSubscribedProduct ? 
          <React.Fragment>   
            <br/>
            <Button 
              className={classes.round} 
              variant="outlined" 
              href="customer-portal">
                Manage your subscription
            </Button>
          </React.Fragment> : undefined
        }
      </div>

      <Divider/>
      <div className={classes.profileMenuAction}>
        <Button variant="outlined" onClick={this.handleSignout.bind(this)}>Sign Out</Button>
      </div>
    </div>
  }
}

Profile = connect(
  (state) => ({
    customer: _.get(state, 'subscribe.customer'),
    user: _.get(state, 'auth.user')
  }),
  (dispatch) => ({})
)(Profile);

export default withRouter(withStyles(styles)(Profile));