import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';


const styles = (theme) => ({
  root: {
    padding: 0
  },
  space: {
    padding: 20
  },
  listItemAction: {
    padding: 0,
    textTransform: 'none'
  }
});


class Alerts extends React.Component {

  render() {

    let {alerts, classes} = this.props;
    
    if (alerts.length <= 0) {
      return <Typography className={classes.space} variant="body2">No alerts</Typography>;
    }

    
    return <List>
      {
        _.map(alerts, a => {
          return (
            <ListItem key={a.id}>
              <ListItemAvatar>
                  <InfoIcon />
              </ListItemAvatar>
  
              <ListItemText
                  primary={a.title}
                  primaryTypographyProps={{
                    variant: "h6",
                    color: "textPrimary"
                  }}
                  secondary={
                    <Typography gutterBottom variant="body1" color="textSecondary">
                      {a.description}
                      <br/>
                      {
                        a.action ? 
                          <Button className={classes.listItemAction} color="secondary" onClick={() => {this.props.history.push(a.action.route)}}>
                            {a.action.text}
                          </Button> : null
                      }
                    </Typography>}
              />
              
              
            </ListItem>
          )
      })
      }
    </List>
  }
}

Alerts = connect(
  (state) => ({
    alerts: _.get(state, 'alerts.alerts'),
  }),
  (dispatch) => ({
  })
)(Alerts)

export default withRouter(withStyles(styles)(Alerts));
