import { combineReducers } from 'redux';
import individual from './individual';
import subscribe from './subscribe';
import auth from './auth';
import alerts from './alerts';
import errorReducer from './error';
import schemas from './schema';
import {reducer as formReducer} from 'redux-form';


const rootReducer = combineReducers({
    app: individual,
    auth: auth,
    form: formReducer,
    subscribe: subscribe,
    error: errorReducer,
    alerts: alerts,
    schemas: schemas
});

export default rootReducer;