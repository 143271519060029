
import typeToReducer from 'type-to-reducer';
import { GET_UNAUTH_CREDENTIALS, SIGN_IN, SIGN_OUT } from '../actions/ActionTypes';
//import { FULFILLED, PENDING } from 'redux-promise-middleware';

let initialState = {
    credentialsLoading: false,
    user: undefined
};

export default typeToReducer({
    [SIGN_IN]: (state, action) => {
        return {
            ...state,
            user: action.user
        }
    },
    [SIGN_OUT]: (state, action) => {
        return {
            ...state,
            user: null
        }
    },
    [GET_UNAUTH_CREDENTIALS]: {
        PENDING: (state, action) => {
            return {
                ...state,
                credentialsLoading: true
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                ...action.payload,
                credentialsLoading: false
            }
        }
    }
}, initialState);