
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OU';

export const GET_UNAUTH_CREDENTIALS = 'GET_UNAUTH_CREDENTIALS';

export const GENEREATE = 'GENEREATE';
export const REGENEREATE = 'REGENEREATE';
export const FROM_GENOTYPE = 'FROM_GENOTYPE';
export const MUTATE = 'MUTATE';
export const CLEAR_INDIVIDUAL = 'CLEAR_INDIVIDUAL'
export const GET_LATEST_INDIVIDUAL = 'GET_LATEST_INDIVIDUAL'
export const SET_LATEST_INDIVIDUAL = 'SET_LATEST_INDIVIDUAL'

export const GET_CHROMOSOME_OPTIONS = 'GET_CHROMOSOME_OPTIONS';
export const SET_CHROMOSOME = 'SET_CHROMOSOME';
export const IMAGE_LOADED = 'IMAGE_LOADED';

export const DOWNLOAD_PHENOTYPE = 'DOWNLOAD_PHENOTYPE';

export const OEPN_CHROMOSOME_EDITOR = 'OEPN_CHROMOSOME_EDITOR';
export const CLOSE_CHROMOSOME_EDITOR = 'CLOSE_CHROMOSOME_EDITOR';

export const REQUEST_HIGH_RES_IMAGE = 'REQUEST_HIGH_RES_IMAGE';

export const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION';
export const CREATE_CREDITS_CHECKOUT_SESSION = 'CREATE_CREDITS_CHECKOUT_SESSION';
export const CLEAR_CREDITS_CHECKOUT_SESSION_URL = 'CLEAR_CREDITS_CHECKOUT_SESSION_URL';

export const CREATE_CUSTOMER_PORTAL = 'CREATE_CUSTOMER_PORTAL';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const REDIRECT_TO_SUBSCRIBE = 'REDIRECT_TO_SUBSCRIBE';
export const GET_CUSTOMER = 'GET_CUSTOMER';

export const GET_ONDEMAND_PLANS = 'GET_ONDEMAND_PLANS';

export const CLEAR_API_ERROR = 'CLEAR_API_ERROR';

export const GET_STYLES = 'GET_STYLES';
export const SET_SELECTED_STYLE = 'SET_SELECTED_STYLE';

export const GET_SCHEMA = 'GET_SCHEMA';

export const GET_PHENOTYPE_URL = 'GET_PHENOTYPE_URL';
export const CLEAR_PHENOTYPE_URL = 'CLEAR_PHENOTYPE_URL';