/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:fe6c9a74-b090-4518-a374-c05e31330b72",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Dy3OUdJ0K",
    "aws_user_pools_web_client_id": "5f89dsppf4743br6vki8gj9bfj",
    "oauth": {},
    "aws_user_files_s3_bucket": "kandinskyio-webprod-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
