import React from 'react';
import axios from 'axios';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {RFTextField} from '../components/reduxform/RFTextField';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';



const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  formField: {
    marginTop: 10
  },
  center: {
    margin: 'auto'
  }
});

const formUrl = 'https://script.google.com/macros/s/AKfycby5p-x96Yxn46F4lnoQwZg3MQXk8jQIcnP6QEIM0AJgygIcmdOr/exec';

class ContactForm extends React.Component {

  constructor() {
    super();
    this.state = {
      sending: false
    }
  }

  async handleSubmit(e) {
    e.preventDefault()

    if (this.state.questionCompleted) {
      return;
    }

    const {name, email, question} = this.props;

    this.setState({
      sending: true
    });

    await axios.get(`${formUrl}?name=${name}&email=${email}&question=${encodeURIComponent(question)}`);

    this.setState({
      sending: false,
      questionCompleted: true
    });    

  }

  render() {
    let {classes} = this.props;

    return <form onSubmit={this.handleSubmit.bind(this)}>
        <Container maxWidth="sm">
          <Card>
            <CardHeader title="Send us your questions, requests, or toughts."/>
            
            <CardContent className={classes.container}>
              <Field
                className={classes.formField} 
                component={RFTextField}
                variant="outlined"
                required 
                label="You Name"
                name="name"/>

              <Field 
                className={classes.formField} 
                component={RFTextField}
                required 
                variant="outlined"
                label="What's Your Email?"
                name="email" 
                type="email"/>
              
              <Field 
                className={classes.formField} 
                component={RFTextField}
                multiline
                label="Your questions..."
                name="question"
                variant="outlined"
                inputProps={{maxLength: 1024}}
                rows={20}
                rowsMax="30"/>

            </CardContent>

            <CardActions>              
              <Button
                disabled={this.state.sending}
                type="submit" 
                size="medium" 
                color={this.state.questionCompleted ? "default": "primary"}
                variant={this.state.questionCompleted ? "outlined": "contained"}
                className={classes.center}>
                  
                {this.state.questionCompleted ? "Thank you!": "SEND"}
              </Button>
            </CardActions>
          </Card>
        </Container>
      </form>
    
  }
}

const selector = formValueSelector('contact')

ContactForm = reduxForm({
  form: 'contact'
})(ContactForm);

ContactForm = connect(
  (state) => ({
    name: selector(state, 'name'),
    email: selector(state, 'email'),
    question: selector(state, 'question')
  }),
  (dispatch) => {
  }
)(ContactForm)

export default withStyles(styles)(ContactForm);