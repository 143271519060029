import React from 'react';
import { Colorscale } from 'react-colorscales';
import { makeStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import './ColormapChromosomeEditor.css';
import colormapList from './colormaplist'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'center'
  },
  list: {
    zIndex: 2,
    minWidth: 250,
  },
}));


const ColormapChromosomeEditorFn = (props) => {
  const classes = useStyles();

  return <List >
    {
      _.map(colormapList, (colors, value) => {
        return <ListItem
          key={value}
          className={classes.root}
          button
          onClick={(e) => {
            e.preventDefault();
            const [p, n]  = value.split("-")
            props.chromosome['palette'] = p
            props.chromosome['name'] = n
            props.chromosomeUpdated(props.chromosome)
          }}
          selected={`${props.chromosome['palette']}-${props.chromosome['name']}` === value}>

          <Colorscale  colorscale={colors} />  
        </ListItem>
        }
      
      )
    }
  </List>
}

export default ColormapChromosomeEditorFn;


// class ColormapChromosomeEditor extends React.Component {

//   componentDidMount() {
//     this.setState({
//       selectedColorMap: this.props.genotype
//     });
//   }

//   handleChange = (colormapName) => {
//     this.props.onColormapChanged(colormapName);
//   }

//   handleSelectClose = () => {
//     this.props.onClose();
//   }

//   render() {
//     let {classes, options} = this.props;
    
//     return (
//       <Drawer
//         id="colormapchromosome"
//         className={classes.drawer}
//         open={true}
//         onClose={this.handleSelectClose}
//         classes={{
//             paper: classes.drawerPaper,
//         }}
//       >
          
//         <div className={classes.toolbar} />
        

//         <List>
//           <ListSubheader>
//               Select a palette
//           </ListSubheader>
//           {
//               _.map(options, (colors, value) => (
//                 <ListItem
//                   key={value}
//                   button
//                   component={
//                     (props) => {
//                       return <Button 
//                         className={classes.drawerPaper}
//                         onClick={this.handleChange.bind(this, value)}>
//                           <Colorscale
//                             colorscale={colors}
//                           />
//                         </Button>
//                     }
//                   }
//                 />
                
//               )
//               )
//             }
//         </List>
//       </Drawer>
//     )
//   }

//   render_() {

//     let { options, classes } = this.props;

//     return (
//       <Select
//         onClick={() => { }}
//         className={classes.select}
//         value={this.props.genotype}
//         renderValue={(value) => {
//           return (
//             <Colorscale
//               className={classes.colorScale}
//               width={300}
//               onClick={() => { }}
//               colorscale={options[value]}
//             />
//           )
//         }}
//       >
//         {
//           _.map(options, (colors, value) => (
//             <MenuItem
//               key={value}
//               value={value}
//               onClick={() => { }}
//               component={
//                 (props) => {
//                   //console.log('props', props)
//                   return <Colorscale
//                     colorscale={colors}
//                     onClick={this.handleChange.bind(this, value)}>
//                       {props.children}
//                     </Colorscale>
//                 }
//               }
//             />
//           )
//           )
//         }
//       </Select>
//     )
//   }
// }

//export default withStyles(styles)(ColormapChromosomeEditor);