import React, { useEffect, useState } from 'react';
import { CircularProgress, makeStyles } from "@material-ui/core";

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Helmet } from 'react-helmet';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import { Hub, Auth, Amplify } from 'aws-amplify';


import Authentication from './containers/auth/AmplifyAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import ContactForm from './containers/Contact';
import About from './components/About';
import Pricing from './components/pricing/Pricing';
import MobileLanding from './components/MobileLanding';
import { BrowserView, TabletView, MobileOnlyView } from 'react-device-detect';
import KandinkysAppBar from './components/KandinkysAppBar';
import SubscribeCheckout from './containers/subscription/SubscribeCheckout';
import SubscriptionSuccess from './containers/subscription/SubscriptionSuccess';


import {
    signIn, signOut
} from './actions/AuthActions'



import {
    getSubscription
} from './actions/SubscribeActions';

import awsconfig from './aws-exports';
import ManageSubscription from './containers/subscription/ManageSubscription';
import ApiError from './containers/ApiError';
import PrivacyPolicy from './containers/PrivacyPolicy';
import Terms from './containers/Terms';
import ImageEvolver from './components/ImageEvolver';
import { Toolbar } from '@material-ui/core';
import Paywall from './components/pricing/Paywall';



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const isLocalEnv = () => {
    return process.env.REACT_APP_ENDPOINT.includes('localhost');
}

Amplify.configure({
    Auth: {
        identityPoolId: awsconfig["aws_cognito_identity_pool_id"],
        userPoolId: awsconfig["aws_user_pools_id"],
        userPoolWebClientId: awsconfig["aws_user_pools_web_client_id"],
        region: awsconfig["aws_cognito_region"]
    },
    Storage: {
        AWSS3: {
            bucket: awsconfig["aws_user_files_s3_bucket"],
            region: awsconfig["aws_user_files_s3_bucket_region"],
        },
        customPrefix: {
            private: '',
            public: '',
            protected: ''
        }
    },
    API: {
        endpoints: [
            {
                name: "PSMApi",
                endpoint: process.env.REACT_APP_PSM_ENDPOINT
            },
            {
                name: "AevaService",
                endpoint: process.env.REACT_APP_ENDPOINT,
                custom_header: async () => {
                    if (isLocalEnv()) {
                        console.log('running locally');
                        let user = await Auth.currentAuthenticatedUser();
                        if (user) {
                            return {
                                Authorization: user.signInUserSession.accessToken.jwtToken
                            }
                        }
                        
                    }
                }
            },
        ]
        
    }
})

const appStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    authContainer: {
        width: '100%'
    },
    appbarSpacer: theme.mixins.toolbar
}))


const themeX = createMuiTheme({
    palette: {
        background: {
            default: "#fff"
        }
    }
});

const AppF = (props) => {
    const dispatch = useDispatch();
    const classes = appStyles();
    const user = useSelector((state) => state.auth.ser);
    const [userStatusKnown, setUserStatusKnown] = useState(false);
    
    const onAuthEvent = (data) => {
        let event = _.get(data, 'payload.event');

        if (event === 'signOut') {
            dispatch(signOut());
            props.history.push('/');
        }

        if (event === 'signIn') {
            let cognitoUser = _.get(data, 'payload.data');
            dispatch(signIn(cognitoUser));
            dispatch(getSubscription());

            let params = new URLSearchParams(props.location.search);
            let redirectUrl = params.get("redirectUrl");

            props.history.push(redirectUrl ? redirectUrl : '/');
        }
    }

    useEffect(async () => {
        Hub.listen('auth', onAuthEvent.bind(this));

        try {
            let user = await Auth.currentAuthenticatedUser();
            await Auth.currentCredentials();

            dispatch(signIn(user));
            dispatch(getSubscription());
        } catch (err) {
            dispatch(signOut());
        } finally {
            setUserStatusKnown(true);
        }
    }, []);

    if (!userStatusKnown) {
        return <CircularProgress size={24} color="inherit"/>
    }
    
    const routes = (
        <Switch>
            <Route exact path="/">
                <ImageEvolver />
            </Route>

            <Route exact path="/signin">
                <div className={classes.authContainer}>
                    <Authentication authState="signIn" />
                </div>
            </Route>

            <Route exact path="/signup">
                <div className={classes.authContainer}>
                    <Toolbar />
                    <Authentication authState="signUp" />
                </div>
            </Route>

            <Route exact path="/about">
                <About />
            </Route>

            {/* <Route exact path="/howitworks">
                    <HowItWorks />
                </Route> */}

            <Route exact path="/contact">
                <div className={classes.authContainer}>
                    <Toolbar />
                    <ContactForm />
                </div>
            </Route>

            <Route exact path="/pricing">
                <div className={classes.authContainer}>
                    <Toolbar />
                    <Pricing />
                </div>
            </Route>

            <Route exact path="/customer-portal">
                <ManageSubscription />
            </Route>

            <Route
                exact
                path="/subscribe/:product/">
                <Elements stripe={stripePromise}>
                    <SubscribeCheckout />
                </Elements>

            </Route>

            <Route exact path="/subscription-success">
                <SubscriptionSuccess />
            </Route>

            <Route exact path="/subscription-cancel">
                <Redirect to="/" />
            </Route>

            <Route exact path="/mobile">
                <div className={classes.authContainer}>
                    <Toolbar />
                    <MobileLanding />
                </div>
            </Route>

            <Route exact path="/privacy">
                <PrivacyPolicy />
            </Route>

            <Route exact path="/terms">
                <Terms />
            </Route>

        </Switch>
    )

    return <MuiThemeProvider theme={themeX}><div className={classes.root}>
        <Helmet>
            <title>Kandinksy.io | HD Wallpapers | Create your own unique wallpaper</title>

            <meta name="description" content="Kandinsky.io's background image generator helps you create your own unique images with custom style and resolution quickly."></meta>

            <meta content="generative art, Wallpaper Maker, Background generator, image generator, wallpaper design, custom wallpaper resolution" property="article:tag"></meta>
        </Helmet>

        <ApiError />
        <CssBaseline />

        <UserAgentProvider ua={window.navigator.userAgent}>
            <React.Fragment>
                <TabletView>
                    <KandinkysAppBar user={user} />
                    {routes}
                </TabletView>
                <MobileOnlyView>
                    <MobileLanding />
                </MobileOnlyView>
                <BrowserView id="browser-view">                        
                    <KandinkysAppBar user={user} />
                    {routes}
                    <Paywall/>
                </BrowserView>
            </React.Fragment>
        </UserAgentProvider>

    </div></MuiThemeProvider>
}

export default withRouter(AppF);