import typeToReducer from 'type-to-reducer';
import ReactGA from 'react-ga';
import _ from 'lodash';


import {
    MUTATE, 
    IMAGE_LOADED,
    GET_CHROMOSOME_OPTIONS,
    SET_CHROMOSOME,
    OEPN_CHROMOSOME_EDITOR,
    CLOSE_CHROMOSOME_EDITOR,
    REQUEST_HIGH_RES_IMAGE,
    GET_STYLES,
    SET_SELECTED_STYLE,
    FROM_GENOTYPE,
    CLEAR_INDIVIDUAL,
    SIGN_IN,
    SIGN_OUT,
    GET_PHENOTYPE_URL,
    CLEAR_PHENOTYPE_URL,
    SET_LATEST_INDIVIDUAL
} from '../actions/ActionTypes';


let initialState = {
    evolutionInProgress: false,
    individual: undefined,
    generating: false,
    regenerating: false,
    mutating: false,
    generateCount: 0,
    imageLoadingState: false,
    chromosomeEditor: false,
    requestHighResInProgress: false,
    styles: undefined,
    loadingStyles: false,
    selectedStyle: undefined,
    allowDownload: false,
    loadingLatestIndividual: false,
    latestIndividual: undefined,
    phenotypeUrl: undefined,
    getPhenotypeUrlInInProgress: false
};



export default typeToReducer({
    [REQUEST_HIGH_RES_IMAGE]: {
        PENDING: (state, action) => ({requestHighResInProgress: true}),
        FULFILLED: (state, action) => ({requestHighResInProgress: false}),
        REJECTED: (state, action) => ({requestHighResInProgress: false})
    },
    [OEPN_CHROMOSOME_EDITOR]: (state, action) => ({
        ...state,
        chromosomeEditor: true
    }),
    [CLOSE_CHROMOSOME_EDITOR]:(state, action) => ({
        ...state,
        chromosomeEditor: false
    }), 
    [CLEAR_PHENOTYPE_URL]: (state, action) => ({
        ...state,
        phenotypeUrl: undefined
    }),
    [GET_PHENOTYPE_URL]: {
        PENDING: (state, action) => {
            return {
                ...state,
                getPhenotypeUrlInInProgress: true
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                getPhenotypeUrlInInProgress: false,
                phenotypeUrl: action.payload
            }
        },
        REJECTED: (state, action) => {
            console.log('rejected')
            return {
                ...state,
                getPhenotypeUrlInInProgress: false,
                phenotypeUrl: null
            }
        }
    },
    "selectedStyleUpdated": (state, action) => {
        return {
            ...state,
            selectedStyle: action.payload
        }
    },
    [SET_LATEST_INDIVIDUAL]: {
        PENDING: (state, action) => {
            return {
                ...state
            }
        },
        FULFILLED: (state, action) => {
            const individual = action.payload;
            return {
                ...state,
                individual: individual,
                //selectedStyle: _.find(state.styles, (style) => style["$id"] === individual["styleId"])
            }            
        },
        REJECTED: (state, action) => {
            return {
                ...state,
                loadingLatestIndividual: false,
                individual: null
            }
        }
    },
    "latestIndividualLoaded": (state, action) => {
        return {
            ...state,
            individual: action.payload
        }
    },
    [SET_CHROMOSOME]: {
        PENDING: (state, action) => {
            return {
                ...state,
                generating: true,
                evolutionInProgress: true
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                generating: false,
                evolutionInProgress: false,
                individual: action.payload
            }
        }
    },
    [GET_CHROMOSOME_OPTIONS]: {
        FULFILLED: (state, action) => {
            //console.log('cmaps', action.payload.data);
            return {
                ...state,
                chromosomeOptions: action.payload
            }
        }
    },
    "imageRegenerated": {
        PENDING: (state, action) => {
            return {
                ...state,
                regenerating: true
            }
        },
        REJECTED: (state, action) => {
            return {
                ...state,
                regenerating: false
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                individual: action.payload,
                regenerating: false
            }
        }
    },
    "individual/generated": {
        PENDING: (state, action) => {

            ReactGA.event({
                category: 'Generate',
                action: 'Generate'
            });
    
            return {
                ...state,
                generating: true,
                evolutionInProgress: true,
                generateCount: state.generateCount + 1,
                imageLoadingState: 'loading'
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                individual: action.payload,
                generating: false,
                evolutionInProgress: false
            }
        }
    },
    "individual/regenerated": {
        PENDING: (state, action) => {
            return {
                ...state,
                regenerating: true,
                evolutionInProgress: true
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                regenerating: true,
                individual: action.payload,
                evolutionInProgress: false
            }
        },
        REJECTED: (state, action) => {
            return {
                ...state,
                regenerating: false,
                evolutionInProgress: false
            }
        }
    },
    [MUTATE]: {
        PENDING: (state, action) => {        
            return {
                ...state,
                mutating: true,
                evolutionInProgress: true,
                imageLoadingState: 'loading'
            }
        },
        FULFILLED: (state, action) => {
            ReactGA.event({
                category: 'Mutate',
                action: 'Mutate'
            });

            return {
                ...state,
                mutating: false,
                evolutionInProgress: false,
                individual: action.payload
            }
        }
    },
    [IMAGE_LOADED]: (state, action) => {
        return {
            ...state,
            imageLoadingState: 'loaded'
        }
    },
    "phenotypeDownloadProgressUpdated": (state, action) => {
        return {
            ...state,
            downloadingPhenotype: true,
            downloadPercentage: action.payload
        }
    },
    "phenotypeDownloaded": (state) => {
        return {
            ...state,
            downloadingPhenotype: false
        }
    },
    [GET_STYLES]: {
        PENDING: (state, action) => {
            return {
                ...state,
                loadingStyles: true
            }
        },
        FULFILLED: (state, action) => {
            const styles = action.payload
            //let selectedStyle = state.selectedStyle;
            //let individual = state.individual;

            // if (!_.isNil(state.selectedStyle) && 
            //     !_.find(styles, (style) => style["$id"] === selectedStyle["$id"])) {
            //     selectedStyle = undefined
            //     individual = undefined
            // }

            return {
                ...state,
                styles: action.payload,
                loadingStyles: false
            }
        }  
    },
    [CLEAR_INDIVIDUAL]: (state, action) => {
        return {
            ...state,
            individual: null
        }
    },
    [FROM_GENOTYPE]: {
        PENDING: (state, action) => {        
            return {
                ...state,
                mutating: true,
                evolutionInProgress: true
            }
        },
        FULFILLED: (state, action) => {

            return {
                ...state,
                mutating: false,
                evolutionInProgress: false,
                individual: action.payload.data
            }
        }
    },
    [SIGN_IN]: (state, action) => {
        return {
            ...state,
            allowDownload: true
        }
    },
    [SIGN_OUT]: (state, action) => {
        return {
            ...state,
            allowDownload: false
        }
    },


}, initialState);

