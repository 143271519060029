
const subscriptionTiers = [
  {
    name: 'Designer',
    price: '9',
    description: '25 unique image downloads',
    route: '/subscribe/designer'
  },
  {
    name: 'Unlimited',
    price: '29',
    description: 'Unlimited image downloads',
    route: '/subscribe/unlimited'
  }
]

const tiers = [
  {
    title: 'Individual',
    price: '0',
    downloadsPerMonth: '3 images',
    description: [
      'Access to all features', 
      'Max resolution: 3840x3840'
    ],
    button: {
      signedOut: {
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
        route: '/signin'
      },
      signedIn: {
        buttonText: 'Already subscribed. Lets Go!',
        buttonVariant: 'outlined',
        route: '/'
      }
    }
  },
  {
    title: 'Designer',
    subheader: 'Most popular',
    price: '9',
    downloadsPerMonth: '25 images',
    description: [
      'Access to all features', 
      'Max resolution: 3840x3840'
    ],
    button: {
        signedIn: {
          buttonText: 'Upgrade',
          buttonVariant: 'contained',
          route: '/subscribe/designer'
        },
        signedOut: {
          buttonText: 'Get started',
          buttonVariant: 'contained',
          route: '/signin?redirectUrl=/subscribe/designer'
        }
    }
    
  },
  
  {
    title: 'Unlimited',
    price: '29',
    downloadsPerMonth: 'Unlimited',
    description: [
      'Access to all features', 
      'Max resolution: 3840x3840'
    ],
    button: {
      signedIn: {
        buttonText: 'Upgrade',
        signedInText: '',
        buttonVariant: 'outlined',
        route: '/subscribe/unlimited'
      },
      signedOut: {
        buttonText: 'Sign up',
        signedInText: '',
        buttonVariant: 'outlined',
        route: '/signin?redirectUrl=/subscribe/unlimited'
      } 
    }
  }
];

export {subscriptionTiers, tiers}