import ComponentRegistry from "../framework/ComponentRegistry";
import RGBChromosomeEditor from './RGBColorChromosomeEditor';
import ColormapChromosomeEditorFn from './colormaplist/ColormapChromosomeEditor';
import RangeNumberGeneEditor from "./RangeNumberGeneEditor";

const registry = new ComponentRegistry()

registry.register(RGBChromosomeEditor, "imageevolver/chromosome/rgb_color_chromosome-v1");
//registry.register(MagnetChromosomeEditor, "MagnetChromosome");
registry.register(ColormapChromosomeEditorFn, '/schemas/colormapList');

registry.register(RangeNumberGeneEditor, 'aeva/range_number_gene-v1');

export default registry;