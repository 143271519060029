
export const saveIndividualToLocalStorageMiddleware = (store) => (next) => (action) => {
  if (action.type.startsWith("individual/") && action.type.endsWith("FULFILLED")) {
    localStorage.setItem("kandinskyio:individual", JSON.stringify(action.payload));
  }

  if (action.type === 'selectedStyleUpdated') {
    localStorage.setItem("kandinskyio:style", JSON.stringify(action.payload));
  }
  
  return next(action);
}