import { GET_SCHEMA } from "../actions/ActionTypes";
import typeToReducer from 'type-to-reducer';
import { PENDING } from "redux-promise-middleware";

const initialState = {
    loading: false
}

export default typeToReducer({
  
  [GET_SCHEMA]: {
    [PENDING]: (state, action) => ({
      loading: true
    }),
    FULFILLED: (state, action) => {
      return {
        ...state,
        loading: false,
        [action.payload["$id"]]: action.payload
      }
    }  
}
}, initialState)