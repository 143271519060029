import { GET_UNAUTH_CREDENTIALS, SIGN_IN, SIGN_OUT } from "./ActionTypes";

/* global AWS */

// AWS.config.region = 'us-east-1';
// AWS.config.credentials = 
//     new AWS.CognitoIdentityCredentials({
//         IdentityPoolId: 'us-east-1:4ab59841-6d4f-44df-a09e-fdae760f6ebb'
//     });

export const signIn = (user) => {
    return (dispatch, getState) => {
        dispatch({
            type: SIGN_IN,
            user: user
        });
    }
}

export const signOut = () => {
    return (dispatch, getState) => {
        dispatch({
            type: SIGN_OUT
        });
    }
}

export const getUnAuthCredentials = () => {
    return (dispatch, getState) => {
        
        dispatch({
            type: GET_UNAUTH_CREDENTIALS,
            payload: new Promise((resolve, reject) => {
                AWS.config.credentials.get(() => {
                    let accessKeyId = AWS.config.credentials.accessKeyId;
                    let secretAccessKey = AWS.config.credentials.secretAccessKey;
                    let sessionToken = AWS.config.credentials.sessionToken;
                    resolve({
                        accessKeyId, secretAccessKey, sessionToken
                    });
                })    
            })
        })
        
    }
}