import React, { useEffect } from 'react';
import _ from 'lodash';

import { makeStyles, Fade, Paper} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { clearPhenotypeUrl, getPhenotypeUrl } from '../actions/ImageActions';
import { getRemotePhenotypeWithSpec } from '../util/individual';


const useStyles = makeStyles(theme => ({
    root: {
        width: 480,
        height: 480
    },
    center: {
        margin: 'auto',
        backgroundColor: 'pink'
    },
    svg: {
        width: 100,
        height: 100
    }
}));

const ImageCardFn = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const evolutionInProgress = useSelector((state) => state.app.evolutionInProgress);
    
    const individual = useSelector((state) => state.app.individual);
    const phenotypeUrl = useSelector((state) => state.app.phenotypeUrl);
    const getPhenotypeUrlInInProgress = useSelector((state) => state.app.getPhenotypeUrlInInProgress);
    
    
    useEffect(()=> {
        if (evolutionInProgress) {
            dispatch(clearPhenotypeUrl())
        }
    }, [evolutionInProgress]);

    useEffect(() => {
        if (individual) {
            if (!getPhenotypeUrlInInProgress) {
                const thumb_phenotype = getRemotePhenotypeWithSpec(individual, 480, 480)
                dispatch(getPhenotypeUrl(thumb_phenotype.location))
            }
        }
    }, [individual])

    return <Paper className={classes.root} square>
        
        <Fade
            in={evolutionInProgress ? false: true}
            timeout={{ enter: 500, exit: 500 }}>    
            <img alt="" 
                src={(evolutionInProgress || _.isUndefined(phenotypeUrl)) ? undefined: phenotypeUrl} />
        </Fade> 
    </Paper>
}

export default ImageCardFn;