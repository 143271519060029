import { GET_SCHEMA } from "./ActionTypes";
import {API} from 'aws-amplify';

export const getSchema = (schemaId) => {
  return async (dispatch, getState) => {
      dispatch({
        type: GET_SCHEMA,
        schemaId: schemaId,
        payload: API.get("AevaService", `/schemas/${encodeURIComponent(schemaId)}`)
      })
  }
}
