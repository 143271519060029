import React from 'react';
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import _ from 'lodash';

import {
  createCustomerPortal
} from '../../actions/SubscribeActions';
import { IconButton, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { CheckCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

class SubscriptionSuccess extends React.Component {

  // componentDidMount() {
  //   if (!this.props.customer || !this.props.customer.currentSubscriedProduct) {
  //     console.log('No customer subscription');
  //     this.props.history.push('/');
  //   }
  // }

  render() {
    let {customer} = this.props;

    if (!customer) {
      return "";
    }

    return <Container maxWidth="md" style={{padding: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center'}} >
      <IconButton disabled>
      <CheckCircle style={{fontSize: 100, color: green[500]}}/>
      </IconButton>
      
      <Typography variant="h3" align="center">Congradualtions</Typography>
      <br/>
      <Typography variant="h4" align="center">We have sucessfully created your {customer.currentSubscribedProduct} subscription</Typography>
      <br/>
      <Typography variant="h6" align="center">
        You can manage your billing and 
        subscription by clicking on the profile icon and selecting 
        Manage your Kandinsky.io subscription
      </Typography>
      <br/>
      <Button 
        variant="contained" 
        color="secondary" 
        style={{margin: 'auto'}}
        onClick={() => {this.props.history.push('/')}} >
        Start Creating!
      </Button>
    </Container>
    
  }
}

SubscriptionSuccess = connect(
  (state) => ({
    customer: _.get(state, 'subscribe.customer')
  }),
  (dispatch) => ({
    createCustomerPortal: bindActionCreators(createCustomerPortal, dispatch)
  })
)(SubscriptionSuccess);


export default withRouter(SubscriptionSuccess);