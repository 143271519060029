

import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';


const styles = theme => ({
  root: {
  },
  content: {
    marginTop: '5vh'
  }
});

class About2 extends React.Component {
    render() {
      return <Container maxWidth="md">
        <Card>
          <CardHeader title="About Kandinsky.io"/>

          <CardContent>
            <Typography color="textPrimary">
              <Typography variant="h5">Who am I?</Typography>
              <p>
                My name is Bardia Khosravi. I am a software engineer. You can find me <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/bardia-khosravi-60514832/">here</a> on LinkedIn.
                I have been working on Kandinkys.io for many years now and I am excited to be at a place where I can share it with you!
              </p>

              <Typography variant="h5">The Story</Typography>
              <p>
                I have always been fascinated with the idea of evolutionary design, a process where computers simulate the process of biological evolution to solve problems in
                architecture, industrial design, and egnieering. Using this process one does not design things explicitly, rather define the space of possiblities, a way to evaluate
                a particular design in terms of fitness, and the computer will search this design space for designs that fit criterias identified by the designer.
              </p>

              <p>
                I began to look at this in the context of art, specifically abstract art. 
                Is it possible to create an algorithm that can automatically generate images that are aesthetically pleasing? 
                In other words is it possible for a computer to create art by itself?                
              </p>
              
              <p>
                This quest began during my studies as a Master's student at the University of Waterloo. I built a number of algorithms that generated complex mathematical 
                functions and through a process rendered said mathematical functions to images. I built a number of algorithms that had the capability to generate
                unlimited number of images of a particular style.
              </p>

              <p>
                Then, representing the mathamtical formulas in an evolutionary algorithms framework, I was able to generate populations of hundred of images, and perform cross over, 
                mutation and other evolutionary functions on the population of images. I was able to evolve the design of an image by 
                allowing the population breed aesthetically fit images through multiple generations. This was very exciting! 
              </p>

              <p>
                The tool that you see here as Kandinsky.io is the platform I build to allow everyone to evolve their own images. 
              </p>

              {/* <p>
                Every image that you see when you click on the generate button is created at runtime using Kandinsky’s algorithms. 
                Kandinsky chooses a colour scheme, selects one of possible algorithms, randomly set the parameters for an image, 
                and renders the image and displays it to you. Every time you click on generate Kandinsky generate a completely image. 
                If you like the image you can choose a resolution that you want to download it in and Kandinsky 
                will regenerate that image in your desired resolution and download it.
              </p> */}

              <Typography variant="h5">
                Mission
              </Typography>

              <p>
                I beleive that evolutionary design can bring a new dimension to creatvity that has not been possible before.
                Our mission is to make it easier for anyone to create digital images without the need to learn complicated photo editing or design tools.
              </p>

              <Typography variant="h5">
                Roadmap
              </Typography>
              
              <p>
                <ul>
                  <li>Ability to generate more image styles will be added.</li>
                  <li>More options for customizing/mutating an image will be provided.</li>
                  <li>You will be allowed to create your own custom color pallete.</li>
                  <li>You will be able to regenerate an image in very high resolutions.</li>
                  <li>and much more...</li>
                  </ul>
              </p>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    }
}

export default withRouter(withStyles(styles)(About2));