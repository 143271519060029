import {API} from 'aws-amplify';

import {
  CREATE_CHECKOUT_SESSION, 
  CREATE_CUSTOMER_PORTAL,
  REDIRECT_TO_SUBSCRIBE,
  GET_ONDEMAND_PLANS,
  CREATE_CREDITS_CHECKOUT_SESSION,
  GET_CUSTOMER,
  CLEAR_CREDITS_CHECKOUT_SESSION_URL} from '../actions/ActionTypes';


export const getOnDemandPlans = () => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ONDEMAND_PLANS,
      payload: API.get("PSMApi", "/on-demand-plans")
    })
  }
}
export const getSubscription = () => {
  return (dispatch, getState) => {
    
    dispatch({
      type: GET_CUSTOMER,
      payload: API.get("PSMApi", "/subscriptions")
    })
  }
}

export const redirectToSubscribeAfterSignIn = (product) => {
  return (dispatch, getState) => {
    dispatch({
      type: REDIRECT_TO_SUBSCRIBE,
      product: product
    })
  }
}

export const createCheckoutSession = (product) => {
  
  return async (dispatch, getState) => {
      dispatch({
        type: CREATE_CHECKOUT_SESSION,
        payload: API.post("PSMApi", "/create-checkout-session", { // OPTIONAL
          body: {
            product: product
          }, // replace this with attributes you need
        })
      })    
  
  }
}


export const clearCreditsCheckoutSessionUrl = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CLEAR_CREDITS_CHECKOUT_SESSION_URL
    })
  }
}

export const createCreditsCheckoutSession = (plan) => {
  
  return async (dispatch, getState) => {
      dispatch({
        type: CREATE_CREDITS_CHECKOUT_SESSION,
        payload: API.post("PSMApi", "/create-credits-checkout-session", {
          body: {
            planId: plan["id"]
          }
        })
      })    
  
  }
}

export const createCustomerPortal =() => {
  return async (dispatch, getState) => {
    dispatch({
      type: CREATE_CUSTOMER_PORTAL,
      payload: API.get("PSMApi", "/customer-portal")
    })
  }
}

export const createSubscription = ({ customerId, paymentMethodId, product }) => {
  return async (dispatch, getState) => {
    // let user = await Auth.currentAuthenticatedUser();
    // //let userInfo = await Auth.currentUserInfo();

    // dispatch({
    //     type: CREATE_SUBSCRIPTION,
    //     payload: axios.post(`${END_POINT}/create-subscription`, {
    //       product: product,
    //       paymentMethodId: paymentMethodId,
    //       customerId: customerId
    //     },{
    //       headers: {
    //           Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`
    //       }
    //   })
    // })
  }
}
