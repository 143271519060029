import { GET_STYLES } from "./ActionTypes"
import {API} from 'aws-amplify';

export const setSelectedStyle = (style) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "selectedStyleUpdated",
            payload: style    
        })
    }
}

export const getStyles = () => ({
    type: GET_STYLES,
    payload: API.get("AevaService", "/styles")
})
