

import _ from 'lodash';
import typeToReducer from 'type-to-reducer';
import { 
  GET_CUSTOMER
} from '../actions/ActionTypes';

let ALERTS = [
  {
    "id": "subscription-past-due-alert",
    "subscriptionStatus": "past_due",
    "title": "Subscription Past Due",
    "description": "Payment for your subscription is past due",
    "type": "info",
    "action": {
      "text": "Manage Billing",
      "route": "/customer-portal"
    } 
  },
  {
    "id": "subscription-unpaid-alert",
    "subscriptionStatus": "unpaid",
    "title": "Unpaid Subscription",
    "description": "Multiple attempts to charge your card was unsuccessful. Please update your payment information.",
    "type": "info",
    "action": {
      "text": "Manage Billing",
      "route": "/customer-portal"
    } 
  }
]


let initialState = {
    alerts: []
};

export default typeToReducer({

    [GET_CUSTOMER]: {
        FULFILLED: (state, action) => {
          const customer = action.payload.result;
          let alerts = [];

          if (customer) {
            alerts = _.filter(ALERTS, (a) => {
              return a.subscriptionStatus === customer.subscriptionStatus
            });
          }

          return {
            ...state,
            alerts: alerts
          }
        }
    }
}, initialState);