
import typeToReducer from 'type-to-reducer';
import { CREATE_CHECKOUT_SESSION,
CREATE_CUSTOMER_PORTAL, GET_CUSTOMER,
REDIRECT_TO_SUBSCRIBE,
GET_ONDEMAND_PLANS,
SIGN_OUT,
CREATE_CREDITS_CHECKOUT_SESSION,
CLEAR_CREDITS_CHECKOUT_SESSION_URL} from '../actions/ActionTypes';

let initialState = {
    credentialsLoading: false,
    creatingCheckoutSession: false,
    checkoutSessionError: false,
    customer: undefined,
    portalSessionUrl: undefined,
    redirectToSubscribe: undefined,
    creditsCheckoutSessionUrl: undefined,
    getOnDemandPlansInProgress: undefined,
    onDemandPlans: undefined
};

export default typeToReducer({
    [GET_ONDEMAND_PLANS]: {
        PENDING: (state, action) => {
            return {
                ...state,
                getOnDemandPlansInProgress: true
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                onDemandPlans: action.payload.result,
                getOnDemandPlansInProgress: false
            }
        },
        REJECTED: (state, action) => {
            console.log('failed', action);
            return {
                ...state,
                onDemandPlans: null,
                getOnDemandPlansInProgress: false
            }
        }
    },
    [SIGN_OUT]: (state, action) => {
        return {
            ...initialState,
            customer: null
        }
    },
    [GET_CUSTOMER]: {
        FULFILLED: (state, action) => {
            return {
                ...state,
                customer: action.payload.result
            }
        },
        REJECTED: (state, action) => {
            console.log('failed', action);
            return {
                ...state,
                customer: null
            }
        }
    },
    [REDIRECT_TO_SUBSCRIBE]: (state, action) => {
        return {
            ...state,
            redirectToSubscribe: action.product
        }
    },
    [CREATE_CUSTOMER_PORTAL]: {
        PENDING: (state, action) => {
            return {
                ...state
            }
        },
        FULFILLED: (state, action) => {
            console.log('session url', action.payload);
            return {
                ...state,
                portalSessionUrl: action.payload.result
            }
        },
        REJECTED: (state, action) => {
            console.log('failed', action);
            return {
                ...state
            }
        }
    },
    [CREATE_CREDITS_CHECKOUT_SESSION]: {
        PENDING: (state, action) => {
            return {
                ...state,
                creatingCheckoutSession: true
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                sessionId: action.payload.result.sessionId,
                creatingCheckoutSession: false
            }
        },
        REJECTED: (state, action) => {
            console.log('failed', action);
            return {
                ...state,
                checkoutSessionError: true,
                creatingCheckoutSession: false
            }
        }
    },
    [CLEAR_CREDITS_CHECKOUT_SESSION_URL]: (state, action) => {
        return {
            ...state,
            creditsCheckoutSessionUrl: undefined
        }
    },
    [CREATE_CHECKOUT_SESSION]: {
        PENDING: (state, action) => {
            return {
                ...state,
                creatingCheckoutSession: true
            }
        },
        FULFILLED: (state, action) => {
            return {
                ...state,
                sessionId: action.payload.result.sessionId,
                checkoutSessionError: false,
                creatingCheckoutSession: false
            }
        },
        REJECTED: (state, action) => {
            console.log('failed', action);
            return {
                ...state,
                checkoutSessionError: true,
                creatingCheckoutSession: false
            }
        }
    }
}, initialState);