class ComponentRegistry {
  constructor(registry = {}) {
    this._registry = registry;
  }

  register(component, type) {
    this._registry[type] = component
  }

  getComponent(type) {
    if (!this._registry.hasOwnProperty(type)) {
      return null;
    }

    return this._registry[type];
  }
}

export default ComponentRegistry;
