import React from 'react';
import ring from './ring.svg';

class SpinnerView extends React.Component {

    render() {
        return (
            <div className={this.props.loadClass}>
                <div className="loader-inner">
                    <img alt="Loading..." src={ring}/>
                </div>
            </div>
        );
    }

}

export default SpinnerView;
