import React, {useState} from 'react';
import { CompactPicker } from 'react-color'

const RGBChromosomeEditor = (props) => {
  const [selectedColor, setSelectedColor] = useState(
    {
      r: Math.floor(props.chromosome["red"]*255), 
      g: Math.floor(props.chromosome["green"]*255),
      b: Math.floor(props.chromosome["blue"]*255)
    }
  );

  return <CompactPicker 
            color = {selectedColor}
            onChangeComplete={
              (color) => {
                setSelectedColor(color.rgb)
                props.chromosomeUpdated({
                  red: color.rgb.r/255.0,
                  green: color.rgb.g/255.0,
                  blue: color.rgb.b/255.0
                })
              }
            }/>
}

export default RGBChromosomeEditor;