import React from 'react';
import { Authenticator, Button, Heading, Image, Text, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const components = {

  Header() {
    const {tokens} = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        
      </View>
    )
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },

  
}

const Authentication = () => (
  <Authenticator loginMechanisms={['email']} components={components}>
    {({ signOut, user }) => (
      <main>
        <h1>Hello {user.username}</h1>
        <button onClick={signOut}>Sign out</button>
      </main>
    )}
  </Authenticator>
);

export default Authentication;


// import React from 'react';
// import { 
//   SignIn, 
//   Authenticator,
//   ForgotPassword,
//   SignUp, ConfirmSignUp, ConfirmSignIn, Loading, VerifyContact, RequireNewPassword, TOTPSetup, 
// } from 'aws-amplify-react';

// const Authentication = () => (
//   <Authenticator hideDefault={true}>  
//       <SignIn/>
//       <ConfirmSignIn/>
//       <RequireNewPassword/>
//       <SignUp signUpConfig={{
//           hiddenDefaults: ['phone_number', 'email'],
//           header: 'Create your Kandinsky.io Account'
//       }}/>      
//       <ConfirmSignUp/>
//       <VerifyContact/>
//       <ForgotPassword/>
//       <TOTPSetup/>
//       <Loading/>          
//   </Authenticator>
// )

// export default Authentication;


