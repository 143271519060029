import {Auth, API, Storage} from 'aws-amplify';

import axios from 'axios';

import { getRemotePhenotypeWithSpec } from '../util/individual';

import { 
    MUTATE, 
    IMAGE_LOADED,
    GET_CHROMOSOME_OPTIONS,
    SET_CHROMOSOME,
    OEPN_CHROMOSOME_EDITOR,
    CLOSE_CHROMOSOME_EDITOR,
    REQUEST_HIGH_RES_IMAGE,
    FROM_GENOTYPE,
    CLEAR_INDIVIDUAL,
    CLEAR_PHENOTYPE_URL,
    GET_PHENOTYPE_URL
} from './ActionTypes';


let END_POINT = process.env.REACT_APP_ENDPOINT;

const getRoute = async (authenticatedRoute, unUthenticatedRoute) => {
    let route = unUthenticatedRoute;

    try {
        let user = await Auth.currentAuthenticatedUser();
        route = user ? authenticatedRoute : unUthenticatedRoute
    } catch(e) {}

    return route
}

const getHeaders = async () => {
    try {
        let user = await Auth.currentAuthenticatedUser()
        return user ? {
            Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
            "X-Amz-Security-Token": user.signInUserSession.accessToken.jwtToken,
            "Cognito-Id-Token": user.signInUserSession.idToken.jwtToken
        } : undefined
    
    } catch(e) {
        console.log("exception getHeaders",e)
        return undefined
    }
}

export const getLatestIndividual = () => {
    return async (dispatch, getState) => {
        try {
            const individual = JSON.parse(localStorage.getItem("kandinskyio:individual"))
            const style = JSON.parse(localStorage.getItem("kandinskyio:style"));

            dispatch({
                type: "latestIndividualLoaded",
                payload: individual
            });

            dispatch({
                type: "selectedStyleUpdated",
                payload: style 
            });
    
        } catch(e) {
            dispatch({
                type: "latestIndividualLoaded",
                payload: null
            });
            dispatch({
                type: "selectedStyleUpdated",
                payload: null
            });
        }
    }
}

export const generate = (styleId, width, height) => {
    
    return async (dispatch, getState) => {
        dispatch({
            type: "individual/generated",
            payload: API.post("AevaService", "/generate", {
                body: {
                    width: width,
                    height: height,
                    styleId: styleId
                }
            })
        })
    }
}

export function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }


export const downloadPhenotype = (individual, styleId, width, height) => {
    return async (dispatch, getState) => {
        let remotePhenotype = getRemotePhenotypeWithSpec(individual, width, height);
        
        if (!remotePhenotype) {
            const promise = API.post("AevaService", "/regenerate", {
                body: {
                    individual: individual,
                    width: width,
                    height: height,
                    styleId: styleId
                }
            });

            dispatch({
                type: "imageRegenerated",
                payload: promise
            });

            const updatedIndiviual = await promise;

            remotePhenotype = getRemotePhenotypeWithSpec(updatedIndiviual, width, height);
        }

        _downloadRemotePhenotype(remotePhenotype, dispatch);
    }
}

const _downloadRemotePhenotype = async (remotePhenotype, dispatch) => {
    dispatch({
        type: "phenotypeDownloadProgressUpdated",
        payload: 0
    })

    const result = await Storage.get(remotePhenotype.location, {
        download: true,
        progressCallback(progress) {
            dispatch({
                type: "phenotypeDownloadProgressUpdated",
                payload: progress.loaded/progress.total
            })
        }
    })

    downloadBlob(result.Body, 'abstract-image-kandinskyio.png');
    
    dispatch({
        type: "phenotypeDownloaded"
    })
}

export const regenerate = (individual, width, height) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "individual/regenerated",
            payload: API.post("AevaService", "/regenerate", {
                individual: individual,
                width: width,
                height: height
            })
        })
    }
}

export const createIndividual = (styleId, genotype) => {
    return async (dispatch, getState) => {
        dispatch({
            type: "individualCreated",
            payload: API.post("AevaService", `/individual`, {
                body: {
                    styleId: styleId,
                    genotype: genotype
                }
            })
        })
    }
}

// deprecated
export const fromGenotype = (genotype, styleId, width, height) => {
    return async (dispatch, getState) => {
        const route = await getRoute("api/genotype", "genotype")
        
        dispatch({
            type: FROM_GENOTYPE,
            payload: axios.post(`${END_POINT}/${route}`, {
                genotype: genotype,
                width: width,
                height: height,
                styleId: styleId
            }, {
                headers: await getHeaders()
            })
        })
    }
}


export const generateSimilarImage = (styleId, genotype, width, height) => {
    return async(dispatch, getState) => {
        dispatch({
            type: MUTATE,
            payload: API.post("AevaService", "/similar", {
                body: {
                    genotype: genotype,
                    styleId: styleId,
                    width: width,
                    height: height
                }
            })
        })    
    }
}

export const imageLoaded = () => {
    return (dispatch, getState) => {    
        dispatch({
            type: IMAGE_LOADED
        })
    }
}


export const getChromosomeOptions = (individual, chromosomeIndex) => {
    return (dispatch, getState) => {
        dispatch({
            type: GET_CHROMOSOME_OPTIONS,
            payload: axios.post(`${END_POINT}/individual/chromosomes/${chromosomeIndex}/chromOptions`, individual['genotype_dto'])
        })
    }
}

export const setChromosome = (individual, chromosome) => {
    return (dispatch, getState) => {
        dispatch({
            type: SET_CHROMOSOME,
            payload: API.post("AevaService", "/individual/chromosomes", {
                body: {
                    individual: individual,
                    chromosome: chromosome
                }
            })
        })
    }
}

export const openChromosomeEditor = (individual, chromosomeIndex) => {
    return (dispatch, getState) => {
        dispatch({
            type: OEPN_CHROMOSOME_EDITOR
        })
    }

}

export const closeChromosomeEditor = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_CHROMOSOME_EDITOR
        })
    }
}

export const clearIndividual = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLEAR_INDIVIDUAL
        })
    }
}

export const requestHighResolutionImage = (spec) => {
    return (dispatch, getState) => {
        dispatch({
            type: REQUEST_HIGH_RES_IMAGE,
            payload: axios.post(`${END_POINT}/highres`, spec)
        });
    }
}

export const getPhenotypeUrl = (key) => {
    return async (dispatch, getState) => {
        //const user = _.get(getState(), 'auth.user');
        //let userInfo = await Auth.currentUserInfo()
        //let s3FileKey = key.split("/").slice(user ? -2: -1).join("/");
        //let level = user ? {level: "private"} : undefined
        //let key = `private/${userInfo["id"]}/${userInfo["username"]}/`
        // if (isLocalEnv()) {
        //     level = "public"
        // }
        
        dispatch({
            type: GET_PHENOTYPE_URL,
            payload: Storage.get(key, {leve: 'private'})
        });
    }
}

export const clearPhenotypeUrl = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CLEAR_PHENOTYPE_URL
        });
    }
}