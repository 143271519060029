
import _ from 'lodash';
import { CLEAR_API_ERROR } from '../actions/ActionTypes';

const initialState = {
  error: null
}

export default function errorReducer(state = initialState, action) {
  
  if (action.type === CLEAR_API_ERROR) {
    return {
      ...state,
      error: null
    }
  }
  
  // from kandinsky-psm
  const errors = _.get(action, 'payload.response.data.errors');

  if (errors && errors.length > 0) {
    return {
      ...state,
      error: {
        type:  errors[0].error,
        message: errors[0].errorMessage
      }
    }
  }

  // This means the backend returned a meaningful error
  let message = _.get(action, 'payload.response.data.message');
  if (message) {
    return {
      ...state,
      error: {
        type: _.get(action, 'payload.response.data.type'),
        message: message
      }
    }
  }

  return {
    ...state,
    error: null
  }
}