import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Screenshot from './screenshot2.png';
import AppStoreBadge from './app-store-badge.png';
import Typography from '@material-ui/core/Typography';
import KandinskyIcon from './icon.png';
import ReactGA from 'react-ga';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    }
  },
  root: {
  },
  container: {
    width: '100%',
    margin: 'auto'
  },
  card: {
  },
  media: {
    width: 445,
    height: 'auto',
    [theme.breakpoints.down("sm")]: {
      width: 220,
      margin: '1vh'
    },
  },
  badge: {
    margin: '5vh',
    width: 335,
    height: 'auto',
    [theme.breakpoints.down("sm")]: {
      width: 200,
      margin: '1.2vh'
    },
  },
  center: {
    width: '50%',
    height: '100%',
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      marginTop: '1vh',
      width: '60%'
    },

  },
  icon: {
    verticalAlign: 'middle',
    width: 150,
    height: 'auto'
  },
  appTitle: {
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: 22
    },
  },
  message: {
    padding: '1vh',
    textAlign: 'center',
    fontSize: 36,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    },
  },
});

function MobileLanding(props) {
  const { classes } = props;

  return (
    <main className={classes.root}>
      <Helmet>
          <title>iPhone Wallpapers | Kandinksy.io, Create your own unique wallpaper</title>
          <meta name="description" content="Create wallpapers, unlimited similar wallpapers, high quality wallpapers that automatically match your screen resolution"></meta>
          <meta content="Art, Wallpaper Maker, Phone Wallpaper, Artificial Intelligence, Generative Art, Computers, Mathematics, Visual Arts" property="article:tag"></meta>
      </Helmet>
      
      <div className={classes.container}>
      
        <div className={classes.center}>
          <img alt="kandinsky.io" src={KandinskyIcon} className={classes.icon}/>
        </div>
        <div className={classes.center}>
          <Typography
            className={classes.appTitle}
            component="h1"
            variant="h4"
            color="inherit">
            Kandinsky.io Wallpapers
          </Typography>
        </div>
        <div className={classes.center}>
          <Typography
            className={classes.message}
            component="h1"
            variant="h2"
            color="inherit">
            A whole new way to create your own unique wallpapers
          </Typography>
        </div>
        <div className={classes.center}>
          <a 
            onClick={() => {
              ReactGA.event({
                category: 'mobile-landing',
                action: 'AppStoreDownload'  
              })
            }}
            href="https://apps.apple.com/us/app/kandinsky-io-wallpapers/id1481018039?ls=1" 
            target="_blank"
            rel="noopener noreferrer">
            <img
              alt="Download From App Store"
              src={AppStoreBadge} 
              className={classes.badge}/>
          </a>
        </div>

        <div className={classes.center}>
          <img 
            alt="App Screenshot"
            src={Screenshot} className={classes.media}/>
        </div>
        
      </div>
            
    </main>
  );
}

MobileLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileLanding);