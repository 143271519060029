import _ from 'lodash';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';

import Profile from '../containers/appbar/Profile';
import Alerts from '../containers/Alerts';
import { Avatar, Popover } from '@material-ui/core';
import KandinskyIcon from '../images/icon.png';

import {
  getSubscription
} from '../actions/SubscribeActions';


const styles = theme => ({
  appBar: {
    zIndex: 98,
  },
  menu: {
    marginLeft: '2vh',
    fontFamily: 'Avenir Next',
    textDecoration: 'none',
    fontSize: '1rem'
  },
  logo: {
    textDecoration: 'none',
    fontFamily: 'Avenir Next'
  },
  greeting: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flex: 1
  },
  noPadding: {
    padding: 0
  },
  title: {
    marginLeft: theme.spacing.unit
  }
});

class KandinskyAppBar extends React.Component {

  constructor() {
    super();
    this.state = {
      anchorEl: null,
      alertsAnchorEl: null
    }

    this.handleProfileMenuClick = this.handleProfileMenuClick.bind(this);
    this.handleProfileMenuClose = this.handleProfileMenuClose.bind(this);

    this.handleAlertsMenuClick = this.handleAlertsMenuClick.bind(this);
    this.handleAlertsMenuClose = this.handleAlertsMenuClose.bind(this);
  }

  handleProfileMenuClick(event) {

    this.props.getSubscription();

    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handleAlertsMenuClick(event) {
    this.setState({
      alertsAnchorEl: event.currentTarget
    });
  }

  _hideMenu(anchorName) {
    this.setState({
      [anchorName]: null
    })
  }

  _hideAlertsMenu() {
    this._hideMenu("alertsAnchorEl")
  }

  handleProfileMenuClose() {
    this._hideMenu("anchorEl");
  }

  handleAlertsMenuClose() {
    this._hideAlertsMenu(this.state.alertsAnchorEl);
  }

  handleUpgrade() {
    this.props.history.push("/pricing")
  }
  
  componentDidUpdate(prevState) {
    if (prevState.user && !this.props.user) {
      this._hideMenu("anchorEl");
    }
  }

  render() {
    let {classes, alerts} = this.props;
    
    return (
      <AppBar position="sticky" color="inherit" className={classes.appBar}>
        <Toolbar className={classes.appBar}>
          <Avatar alt="logo" src={KandinskyIcon}/>

          <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
              onClick={() => {
                  this.props.history.push("/")
              }}>

            <Link className={classes.logo} to="/">Kandinsky.io</Link>
          </Typography>

          <Link className={classes.menu} to="/mobile">get the app</Link>
          <Link className={classes.menu} to="/pricing">pricing</Link>
          {/* <Link className={classes.menu} to="/howitworks">how it works?</Link> */}
          <a className={classes.menu} href="/blog">blog</a>
          <a className={classes.menu} href="/blog/about-kandinsky/">about</a>
          <Link className={classes.menu} to="/contact">contact</Link>

          <div className={classes.greeting}>
            {
              this.props.user ? 
                  <React.Fragment>

                  <IconButton 
                    aria-controls="simple-menu" 
                    aria-haspopup="true" 
                    onClick={this.handleProfileMenuClick}
                  >
                    <AccountCircle/>
                  </IconButton>
                  
                  <IconButton 
                    aria-controls="simple-menu" 
                    aria-haspopup="true" 
                    onClick={this.handleAlertsMenuClick}
                  >
                    <NotificationsIcon/>
                    {_.size(alerts) > 0 ? <Typography variant="body2">{_.size(alerts)}</Typography>: null}
                  </IconButton>

                  {/* {
                    customer && !customer.currentSubscribedProduct ?
                      <Button 
                        onClick={this.handleUpgrade.bind(this)} 
                        //variant="outlined" 
                        color="primary">
                          Upgrade
                      </Button> : null
                  } */}
                  
                  <Popover
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleProfileMenuClose}
                  >
                    <Profile />
                  </Popover>
                  
                  <Popover
                    id="alerts-menu"
                    anchorEl={this.state.alertsAnchorEl}
                    keepMounted
                    open={Boolean(this.state.alertsAnchorEl)}
                    onClose={this.handleAlertsMenuClose}
                  >
                    <Alerts />
                  </Popover>

                </React.Fragment>
                :   
                <Link className={classes.logo} to="/signin"><Button color="primary" variant="outlined">sign in</Button></Link>
            }
            
          </div>
          
        </Toolbar>        
      </AppBar>
    )
  }
}

KandinskyAppBar = connect(
  (state) => ({
    alerts: _.get(state, 'alerts.alerts'),
    user: _.get(state, 'auth.user')
  }),
  (dispatch) => ({
    getSubscription: bindActionCreators(getSubscription, dispatch)
  })
)(KandinskyAppBar);

export default withRouter(withStyles(styles)(KandinskyAppBar));