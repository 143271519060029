import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import _ from 'lodash';

import withUser from '../../hoc/withUser';

import {
  createCustomerPortal
} from '../../actions/SubscribeActions';
import SpinnerView from '../../components/SpinnerView';

class ManageSubscription extends React.Component {
  constructor() {
    super();
    this.state = {
      redirected: false
    }
  }

  componentDidMount() {
    if (this.props.user && this.props.customer && this.props.customer.currentSubscribedProduct) {
      this.props.createCustomerPortal();
    }
  }

  render() {
    if (this.props.user && this.props.portalSessionUrl) {
      return window.location.href = this.props.portalSessionUrl
    }

    return <SpinnerView loadClass="full-spinner"/>;
  }
}

ManageSubscription = connect(
  (state) => ({
    portalSessionUrl: _.get(state, 'subscribe.portalSessionUrl')
  }),
  (dispatch) => ({
    createCustomerPortal: bindActionCreators(createCustomerPortal, dispatch)
  })
)(ManageSubscription);

export default withUser(ManageSubscription);