import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import configureStore from './store/configureStore';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route } from "react-router-dom";

const store = configureStore();

ReactGA.initialize(process.env.REACT_APP_GAUID, {
    cookieDomain:'none'
});


ReactDOM.render(
    <Provider store={store}>        
        <Router>
            <Route path="/">
                <App/>
            </Route>
        </Router>
    </Provider>,
    document.getElementById('root')
);

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
