import {Auth} from 'aws-amplify';

const errors = {
  "SubscriptionLimitExceeded": {
    "ignore": true
    // "message": "You have reached your subbscription limit this month. Please upgrade to own more image.",
    // "action": {
    //   "route": "/pricing",
    //   "text": "Upgrade"
    // }
    //"component": StripePaywall,  
  },
  "API": {},
  "CustomerDoesNotExistException": {
    "message": "This customer does not exist. Please sign in again.",
    "executeAction": () => {
      Auth.signOut();
    }
  },
  "ApplicationError": {}
}

export default errors;