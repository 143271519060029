import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom'

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import errors from './Errors'

import {clearApiError} from '../actions/ErrorActions';

class ApiError extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: true
    }

    this.handleClose = this.handleClose.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (!prevProps.error && this.props.error) {
      console.log('is error', this.props.error)
      this.setState({
        open:true
      });

      const executeAction = _.get(errors[this.props.error.type], 'executeAction', null);

      if (executeAction) {
        executeAction();
      }
    }
  }

  handleUpgrade(e) {
    this.props.history.push(errors[this.props.error.type]['action']['route'])
  }

  handleClose() {
    this.setState({
      open: false
    })

    this.props.clearApiError();
  }

  render() {
    if (this.props.error) {
      
      const errorSpec = errors[this.props.error.type]
      let message = 'Whoops. Something went wrong!'
      let action;
      
      if (_.get(errorSpec, "ignore", false)) {
        return "";
      }

      if (_.has(errorSpec, 'component')) {
        const Cmp = errorSpec['component']
        return <Cmp />
      }

      if (errorSpec) {
        message = errorSpec['message'] ? errorSpec['message'] : this.props.error.message;
        action = errorSpec && errorSpec['action'] ? (
          <Button color="secondary" size="small" onClick={this.handleUpgrade.bind(this)}>
            {errorSpec['action']['text']}
          </Button>
        ) : undefined;
      } 
      
      return <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.open} 
        autoHideDuration={10000} 
        message={message}
        onClose={this.handleClose}
        action={action ? 
          <React.Fragment>
            {action}
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>: null
        }
        />
    }
    return "";
  }
}

ApiError = connect(
  (state) => ({
      error: _.get(state, 'error.error')
  }),
  (dispatch) => ({
    clearApiError: bindActionCreators(clearApiError, dispatch)
  })
)(ApiError)

export default withRouter(ApiError);
