import _ from 'lodash';
import ReactGA from 'react-ga';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getStyles, setSelectedStyle} from '../actions/Styles'
import { makeStyles } from '@material-ui/core/styles';
import {Button, ButtonGroup, Toolbar, Typography, 
  CircularProgress, Tooltip, TextField, InputAdornment, Link} from '@material-ui/core';
import ImageCardFn from './ImageCardFn';
import Drawer from '@material-ui/core/Drawer';
import classnames from 'classnames';
import ChromosomeEditor from './ChromosomeEditor';
import { fromGenotype, clearIndividual, generate, generateSimilarImage, getLatestIndividual, downloadPhenotype } from '../actions/ImageActions';
import { Assistant, GetApp, Image } from '@material-ui/icons';
import { Form, Field } from 'react-final-form'
import { Link as RouterLink } from 'react-router-dom';

const IMG_WIDTH = 480
const IMG_HEIGHT = 480

const downloadActionTooltip = {
  "regenerating": "Generating custom resotluion. This might take a moment or two depending on the entered resolution and image style",
  "subscriptionUnpaid": `Your subscription is fee unpaid. Please update your payment information to continue downloading images. 
  Click your profile image and select Manage Subscription button to proceed.`
}

const RenderTextField = (props) => {
    let error = _.get(props, 'meta.error');
    return <TextField
        {...props.input} {...props}
        label={error ? error : props.label}
        error={error ? true : false}
    />
}

const DownloadForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [allowDownload, regenerating, individual, customer, selectedStyle, downloadingPhenotype, downloadPercentage] = useSelector((state) => [
    state.app.allowDownload,
    state.app.regenerating,
    state.app.individual,
    state.subscribe.customer,
    state.app.selectedStyle,
    state.app.downloadingPhenotype,
    state.app.downloadPercentage
  ])
  
  const isSubscriptionUnpaid = customer && customer.subscriptionStatus === 'unpaid';

  return <React.Fragment>
    <Form
      initialValues={{
        "width": 1024,
        "height": 768
      }}
      validate={values => {
        const errors = {}
        
        if (values["width"] > 3840) {
          errors.width = "Can not be greater than 3840"
        }

        if (values["height"] > 3840) {
          errors.height = "Can not be greater than 3840"
        }
        return errors;
      }}
      onSubmit={values => {
        const width = parseInt(values["width"])
        const height = parseInt(values["height"])
        dispatch(downloadPhenotype(individual, selectedStyle["$id"], width, height));        
      }}>
        
      {
        ({ handleSubmit, pristine, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field 
              className={classes.margin} 
              name="width" 
              type="number"
              placeholder="Width"
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        Px
                    </InputAdornment>
                ),
              }}>
              {props => (<RenderTextField {...props}/>)}
            </Field>
            
            <Field 
              className={classes.margin}
              name="height" 
              type="number"
              placeholder="Height"
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        Px
                    </InputAdornment>
                ),
              }}>
              {props => (<RenderTextField {...props}/>)}
            </Field>

            <Tooltip 
              title={isSubscriptionUnpaid ? downloadActionTooltip["subscriptionUnpaid"] : 
              regenerating ? downloadActionTooltip["regenerating"] :
              _.isNil(selectedStyle) ? "Generate an image to download" :  
              "Download image"}>
            <span>
              <Button 
                id="download-button" 
                disabled={!allowDownload || props.disableActions || isSubscriptionUnpaid || downloadingPhenotype}
                variant="outlined"
                color="primary" 
                size="medium"
                endIcon={regenerating || downloadingPhenotype ? <CircularProgress size={24} color="inherit"/> : <GetApp/>}
                type="submit">
                  {regenerating ? "Generating" : downloadingPhenotype? "Downloading" : "Download"}
              </Button>
            </span>
      </Tooltip>
          </form>
        )
      }
    
    </Form>
    
    

    </React.Fragment>
}

const StyleButtons = (props) => {
  const dispatch = useDispatch();
  
  return <ButtonGroup orientation="vertical"
                      color="primary"
                      aria-label="vertical outlined primary button group">
    {
      
      _.map(props.styles, (style) => 
          <Button 
            style={{
              border: 0,
              borderRadius: 0,
              justifyContent: 'flex-start'
            }}
            key={_.uniqueId()}
            color="primary"
            variant={props.selectedStyle && props.selectedStyle["$id"] === style["$id"] ? "contained" : "outlined"}
            onClick={() => {
              ReactGA.event({
                category: 'ImageEvolver',
                action: 'StyleSelected',
                label: style["title"]
              });
              dispatch(clearIndividual());
              dispatch(setSelectedStyle(style));
              dispatch(generate(style["$id"], IMG_WIDTH, IMG_HEIGHT));
            }}>
            {style["title"]}
         </Button>)
    }
    </ButtonGroup> 
}

const useStyles = makeStyles(theme => (
  {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    drawer: {
      width: 250,
      flexShrink: 0,
      zIndex: 97
    },
    drawerPaper: {
      width: 250,
      display: 'flex',
      flexDirection: 'column',
    },
    toolbar: theme.mixins.toolbar,

    drawerHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      textAlign: 'center',
      color: theme.palette.primary.dark,
    },
    drawerHeaderText: {
      fontFamily: 'Avenir Next'
    },
    actionsToolbar: {
      marginLeft: 250,
      //marginRight: 250,
      display: 'flex',
      backgroundColor: '#ffffff',
      borderBottom: '1px solid #0000001f'
    },
    downloadActionsToolbar: {
      marginLeft: 250,
      //marginRight: 250,
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: '#ffffff',
      marginTop: theme.spacing.unit,
      borderTop: '1px solid #0000001f'
    },
    imageCard: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 250,
      //marginRight: 250,
      marginTop: theme.spacing.unit
    },
    progress: {
      position: 'absolute'
    },
    margin: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    }
  }
));

const InitializationStatus = {
  Initialized: "Initialized",
  Initializing: "Initializing",
  NotInitialized: "NotInitialized"
}

const ImageEvolver = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [initializationStatus, setInitializationStatus] = useState(InitializationStatus.NotInitialized);

  const [styles, user, selectedStyle, individual, mutating, generating, regenerating, downloadingPhenotype] = useSelector((state) => [
    state.app.styles,
    state.auth.user,
    state.app.selectedStyle,
    state.app.individual,
    state.app.mutating,
    state.app.generating,
    state.app.regenerating,
    state.app.downloadingPhenotype
  ])

  const _isInitialized = () => {
    return initializationStatus === InitializationStatus.Initialized
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  useEffect(() => {
    setInitializationStatus(InitializationStatus.Initializing);
    
    dispatch(getStyles());
    //dispatch(generate())

    if (!user) {
      dispatch(clearIndividual());
      return;
    }

    dispatch(getLatestIndividual());

  }, []);

  useEffect(() => {        
    if (
      (individual === null || individual) && 
      styles && 
      !_isInitialized()
    ) {      
        setInitializationStatus(InitializationStatus.Initialized);
    }
  });

  useEffect(() => {
    if (_isInitialized() && !selectedStyle && styles && !individual) {
      dispatch(setSelectedStyle(styles[0]));
      dispatch(generate(styles[0]["$id"], IMG_WIDTH, IMG_HEIGHT))
    }
  })
  
  if (initializationStatus === InitializationStatus.NotInitialized ||
      initializationStatus === InitializationStatus.Initializing) {
        return "Loading..."
  }

  const disableImageActions = _.isNil(selectedStyle) || generating || mutating || regenerating || downloadingPhenotype;

  const downloadAction = user ? (
    <DownloadForm disableActions={disableImageActions}/>
  ) : (
    selectedStyle ? (
      <Link 
        component={RouterLink} 
        style={{textDecoration: 'none'}} 
        to="/signin">
          <Button 
            color="primary" 
            endIcon={<GetApp/>} 
            variant="outlined">
              Sign in to download
              </Button>
      </Link>
      
    ) : undefined
  )

  return <div id="image-evolver" className={classes.root}>
    <Drawer
      id="styles-drawer"
      className={classes.drawer}
      variant="permanent"
      open={true}
      classes={{
        paper: classes.drawerPaper,
      }}>       
      <Toolbar/>
      <Typography 
        style={{
          borderBottom: '1px solid',
          borderBottomColor: '#0000001f'
        }}
        className={classnames(classes.toolbar, classes.drawerHeader, classes.drawerHeaderText)} 
        variant="h5">
          Image Styles
      </Typography>

      <StyleButtons styles={styles} selectedStyle={selectedStyle}/>
    </Drawer>
    
    <Toolbar className={classes.actionsToolbar}>
      <Tooltip 
        title={_.isNil(selectedStyle) ? "Please select an image style to generate":                                         
                                        "Generate a new random image of the selected style"}>
        <span>
          <Button 
            id="generate-button"
            color="primary"
            startIcon={<Image />}
            onClick={(() => dispatch(generate(selectedStyle["$id"],IMG_WIDTH, IMG_HEIGHT)))}
            disabled={disableImageActions}>
              Generate New
          </Button>
        </span>        
      </Tooltip>

      <Tooltip 
        style={{marginLeft: 5}}
        title={_.isNil(selectedStyle) ? "Please select an image style to generate":                                         
                                        "Generate a similar image to the one on screen, just slightly different."}>
        <span>
          <Button 
            id="generate-similar-button"
            color="primary"
            startIcon={<Assistant />}
            onClick={(() => dispatch(generateSimilarImage(selectedStyle["$id"], individual["genotype"], IMG_WIDTH, IMG_HEIGHT)))}
            disabled={disableImageActions}>
              Generate Similar
          </Button>
        </span>        
      </Tooltip>

      
    </Toolbar>

    <div className={classes.imageCard}>
      <div className={classes.progress}>
      {(generating || mutating) && <CircularProgress />}
      </div>
      
      <ImageCardFn individual={individual}/>
    </div>
{/* 
    <Drawer
      id="styles-drawer"
      className={classes.drawer}
      variant="permanent"
      anchor="right"
      open={true}
      classes={{
        paper: classes.drawerPaper,
      }}>       
      <Toolbar/>
      <Typography 
        style={{
          borderBottom: '1px solid',
          borderBottomColor: '#0000001f'
        }}
        className={classnames(classes.toolbar, classes.drawerHeader, classes.drawerHeaderText)} 
        variant="h5">
          Options
      </Typography>

      {
      individual && selectedStyle && <ChromosomeEditor 
          genotype={individual['genotype']}
          style={selectedStyle}
          onMutate={(updatedChromosome) => {
            dispatch(
              fromGenotype(
                updatedChromosome, 
                selectedStyle["$id"],
                IMG_WIDTH, IMG_HEIGHT
              )
            )
          }}
        />
      }
    </Drawer> */}

    <Toolbar className={classes.downloadActionsToolbar}>
      {downloadAction}
    </Toolbar>
    
  </div>
}

export default ImageEvolver;