import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
//import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
//import localforage from 'localforage';
import {saveIndividualToLocalStorageMiddleware} from '../middleware/saveIndividualMiddleware';
import { isLocalStorageAvailable } from '../util/localStorage';
import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';

let enhancer;

let middlewareArgs = [thunk, promiseMiddleware()]

if (isLocalStorageAvailable()) {
    middlewareArgs.push(saveIndividualToLocalStorageMiddleware);
}

const middleware = applyMiddleware.apply(this, middlewareArgs);

if (process.env.NODE_ENV !== 'production') {
    const DevTools = require('../containers/DevTools');
    
    enhancer = compose(
        // Middleware we want to use in development
        middleware,
        window.__REDUX_DEVTOOLS_EXTENSION__ ?
            window.__REDUX_DEVTOOLS_EXTENSION__() :
            DevTools.default.instrument()
    );
}  else {
    enhancer = compose(
        // Middleware we want to use in development
        middleware
    );
}

export default (initialState) => {
    //const persistedState = loadState();

    let store = createStore(rootReducer, undefined, enhancer);
    // store.subscribe(() => {
    //     saveState({
    //       app: {
    //           individual: store.getState().app.individual,
    //           selectedStyle: store.getState().app.selectedStyle
    //       }
    //     });
    // });

    return store;
};
