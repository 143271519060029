import _ from 'lodash';
import React, {Component} from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { Link, withRouter } from 'react-router-dom'
import SpinnerView from '../../components/SpinnerView';
import Authentication from '../../containers/auth/AmplifyAuth';
import withUser from '../../hoc/withUser';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import {
  ElementsConsumer
} from '@stripe/react-stripe-js';

import {createCheckoutSession, redirectToSubscribeAfterSignIn} from '../../actions/SubscribeActions';
import { Typography } from '@material-ui/core';
//import { Button } from 'aws-amplify-react';


class SubscribeCheckout extends Component {
  constructor() {
    super();
    this.state = {
      redirected: false
    }
  }

  componentDidMount() {
    if (this.props.subscription) {
      return this.setState({
        alreadySubscribed: true
      }) 
    }

    if (this.props.user) {
      this.props.createCheckoutSession(this.props.match.params.product);
    }
  }

  componentDidUpdate(prev) {
    if (!prev.error && this.props.error) {
      //alert("cant create checkout session");
      return this.props.history.push('/')
    }

    if (
      this.props.user && 
      !this.props.checkoutSessionError &&
      this.props.sessionId && 
      !this.state.redirected
    ) {
        this.setState({ 
          redirected: true
        });

        this.props.stripe.redirectToCheckout({
          sessionId: this.props.sessionId
        });

      } 
  }

  render() {
    if (!this.props.user) {
      return <Container maxWidth="sm">
          Please create an account to continue with signing up for your subscription:
          <Authentication/>
          {/* <AmplifyAuthenticator >
          </AmplifyAuthenticator> */}
        </Container>
    }

    if (this.state.alreadySubscribed) {
      return <Container maxWidth="md">
        <Typography>
          <Box>
            You are already subscribed to 
          </Box>
          <Box fontWeight="fontWeightBold">
          {this.props.customer.currentSubscribedProduct} plan
          </Box>
          <Button><Link to="/customer-portal">Manage Subscription</Link></Button>
        </Typography>
      </Container>
    }
    

    if (!this.props.sessionId) {
      return <SpinnerView loadClass="full-spinner"></SpinnerView>
    }

    return <div></div>
  }

}

SubscribeCheckout = connect(
  (state) => ({
    error: _.get(state, 'error.error'),
    sessionId: _.get(state, 'subscribe.sessionId'),
    creatingCheckoutSession: _.get(state, 'subscribe.creatingCheckoutSession'),
    checkoutSessionError: _.get(state, 'subscribe.checkoutSessionError')
  }),
  (dispatch) => ({
    createCheckoutSession: bindActionCreators(createCheckoutSession, dispatch),
    redirectToSubscribeAfterSignIn: bindActionCreators(redirectToSubscribeAfterSignIn, dispatch)
  })
)(SubscribeCheckout);

SubscribeCheckout = withRouter(withUser(SubscribeCheckout));

const InjectedStribeCheckout = () => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <SubscribeCheckout stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);


export default InjectedStribeCheckout;